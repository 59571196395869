<template>
  <div v-if="!isPermission">
    <!-- no access data -->
    <access-data></access-data>
  </div>
  <div v-else class="mb-4">
    <CCard class="shadow-sm m-2">
      <CCardBody>
        <CRow>
          <CIcon size="2xl" name="cil-basket" class="text-success col-1 col-sm-1 col-lg-1" />
          <CCol col="8" sm="9" md="9" lg="9">
            <select class="custom-select text-dark" v-model="shopObjectId" disabled>
              <option selected>{{ $t('selectshop') }}</option>
              <option v-for="shop in shops" :key="shop.objectId" :value="shop.objectId">
                {{ shop.shopName }} - {{ shop.branchName }}
              </option>
            </select>
          </CCol>
          <div class="col-3 col-sm-2 col-md-2 text-right">
            <CButton block color="light" @click="goback" class="align-items-center">
              <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
            </CButton>
          </div>
        </CRow>
      </CCardBody>
    </CCard>

    <!-- Show Loading indicator -->
    <div v-if="isLoadingProduct">
      <loading-page></loading-page>
    </div>

    <!-- Loaded data -->
    <form v-else class="mt-4">
      <CCol md="12" lg="12">
        <!-- Information -->
        <CCard class="shadow-sm" style="">
          <CCardBody>
            <CAlert color="success" v-if="isSuccess === true">
              {{ $t('createProductSuccess') }}
            </CAlert>
            <div class="row">
              <div class="col-md-10 col-10">
                <h4 class="font-weight-normal text-dark">
                  {{ $t('information') }}
                </h4>
              </div>
              <div class="col-md-2 col-sm-2 col-2 text-right" role="status" v-if="isDeleteData">
                <img 
                  src="/img/trash.png" 
                  style="width: 24px; height: 24px; cursor: pointer;" 
                  v-c-tooltip="{
                    content: 'Delete <strong>' + this.SKUName + '</strong>',
                    placement: 'right',
                  }" 
                  @click="confirmdelete()"
                />
              </div>
              <div v-else class="col-md-2 col-sm-2 col-2 text-right" >
                <img 
                  src="/img/trash.png" 
                  style="width: 24px; height: 24px; cursor: not-allowed; opacity: 0.5; pointer-events: none;" 
                  disabled
                />
              </div>
            </div>
            <br />
            <CRow md="12" sm="12" class="form-group row">
              <CCol md="2" sm="2" class="imagelabel">
                <label class="col-form-label text-right text-dark font-weight-normal">
                  {{ $t('productImg') }}
                </label>
              </CCol>
              <CCol md="4" sm="10" class="mb-3">
                <div v-if="imageData.length > 0"
                  style="text-align: center;box-shadow: 0 0 0 1px #e5e5e5;border-radius: 0.2rem; width: 200px;">
                  <div class="profile-img doc-img">
                    <div class="profile-widget">
                      <div v-if="isEditData">
                        <img :src="imageData" class="img-fluid" alt="product image" @error="noImgUrl"
                          @click="selectcolormodal" />
                      </div>
                      <div v-else>
                        <img :src="imageData" class="img-fluid" alt="product image" @error="noImgUrl" />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else
                  style="text-align: center;box-shadow: 0 0 0 1px #e5e5e5;border-radius: 0.2rem; width: 200px;">
                  <div v-if="isEditData">
                    <CButton :style="{ backgroundColor: colori }" @click="selectcolormodal"
                      style="width: 100%; height: 136px" class="img-fluid">
                      <CIcon>
                        <i class="fas fa-square"></i>
                      </CIcon>
                    </CButton>
                  </div>
                  <div v-else>
                    <CButton :style="{ backgroundColor: colori }" style="width: 100%; height: 136px" class="img-fluid">
                      <CIcon>
                        <i class="fas fa-square"></i>
                      </CIcon>
                    </CButton>
                  </div>
                </div>
              </CCol>
              <!-- <CCol v-if="isSelfOrderEnabled" md="2" class="col-form-label text-dark imagelabel "><label
                  v-html="$t('additionalImage')"></label>
              </CCol> -->
              <CCol v-if="isSelfOrderEnabled" md="4" class="mb-2">
                <CButton key="add-video" @click="selectVideo()"
                  :style="{ position: 'relative', width: '55px', height: '55px', margin: '2px', backgroundColor: currentVideo !== '' ? '#3abc98' : '#d8dbe0', color: '#878787', fontSize: 'medium', overflow: 'hidden' }"
                  class="img-fluid">
                  <!-- Video -->
                  <div v-if="currentVideo !== ''"
                    style="display: flex; align-items: center; justify-content: center;color: white;">
                    <i class="fi fi-rr-film"></i>
                  </div>
                  <div v-else style="display: flex; align-items: center; justify-content: center;">
                    <i class="fi fi-rr-film"></i>
                  </div>
                </CButton>
                <CButton v-for="(index, i) in additionImageAmount - 1" :key="i" @click="selectAdditionalImg(i)"
                  style="position: relative; width: 55px; height: 55px; margin: 2px; background-color: #d8dbe0; color: #878787; font-size: medium; overflow: hidden;"
                  class="img-fluid">
                  <!-- Image -->
                  <img v-if="localAdditionImagePath[i] !== undefined && localAdditionImagePath[i] !== null"
                    @error="noImgUrl" :src="localAdditionImagePath[i]"
                    style="width: 100%; height: 100%; object-fit: cover; position: absolute; top: 0; left: 0;">
                  <!-- '+' placeholder -->
                  <div v-else style="display: flex; align-items: center; justify-content: center;">+</div>
                </CButton>




                <p style="font-size: small;" class="text-grey text-small mt-3">*{{ $t('useInQr') }}</p>
              </CCol>
            </CRow>
            <CModal color="success" :show.sync="uploadVideoModal" :title="$t('uploadVideo')" centered>
              <CRow style="justify-content: center;" v-if="videoOption == ''">
                <CCol md="5" sm="12" @click="videoOption = 'Upload'" class="text-center pt-5 pb-5  m-2"
                  style="border-radius: 10px; border-style: dashed; border-color: #cfcfcf; border-width: 5px;">
                  <i class="fi fi-rr-upload" style="font-size:xx-large;"></i> <br>
                  <p class="mt-2">{{ $t('uploadVideo') }}</p>
                </CCol>
                <CCol md="5" sm="12" @click="videoOption = 'URL'" class="text-center pt-5 pb-5  m-2"
                  style="border-radius: 10px; border-style: dashed; border-color: #cfcfcf; border-width: 5px;">
                  <i class="fi fi-rr-play" style="font-size:xx-large;"></i><br>
                  <p class="mt-2"> Youtube URL</p>
                </CCol>
              </CRow>

              <CRow v-if="videoOption == 'Upload'">
                <label v-if="currentVideoHolder !== ''" class="col text-right pr-4 mb-4" style="color:red;"
                  @click="delteVideo()"> {{ $t('deleteVideo') }}
                </label>
                <video v-if="currentVideoHolder !== ''" style="width: 100%; height: 100%; " :src="currentVideoHolder"
                  controls></video>

                <label class="col-12 ">
                  <div v-if="currentVideoHolder == ''" class="text-center pt-5 pb-3"
                    style="border-radius: 10px; border-style: dashed; border-color: #cfcfcf; border-width: 5px;">
                    <i class="fi fi-rr-upload" style="font-size:xx-large;"></i> <br>
                    <p class="mt-2">{{ $t('uploadVideo') }}</p>
                    <p class="text-danger">*{{ $t('videoSizeNotTooBig') }}</p>
                    <input class="pl-5 " ref="video" type="file" accept="video/*"
                      @change="handleVideoFileChange($event.target)" />
                  </div>
                </label>
              </CRow>
              <CRow v-if="videoOption == 'URL'">
                <LazyYoutube v-if="currentVideoHolder !== ''" :src="currentVideoHolder" />
                <label class="col-12 mt-3">{{ $t('pasteURL') }} <small style="color: rgb(180, 180, 180)">*Ex.
                    https://youtu.be/sRxrwjOtIag?si=3yfcN0XRXnsJlQxM</small> </label>
                <CCol md="12" sm="12">
                  <CInput v-model="currentVideoHolder"></CInput>
                </CCol>

              </CRow>


              <template #footer-wrapper>
                <div></div>
                <CRow class="p-3" v-if="videoOption !== ''">
                  <CCol>
                    <CButton block color="success" v-if="loadingSaveimg === true" v-on:click="saveVideo()">
                      {{ $t('save') }}
                    </CButton>
                    <CButton block color="success" v-else-if="loadingSaveimg === false" disabled>
                      <CSpinner color="white" size="sm" /> {{ $t('save') }}
                    </CButton>
                  </CCol>
                  <CCol>
                    <CButton block color="light" @click="delteVideo()">
                      {{ $t('cancel') }}
                    </CButton>
                  </CCol>
                </CRow>
              </template>
            </CModal>
            <CModal color="success" :show.sync="additionalModal" :title="$t('selectImg')" centered>
              <div class="row">
                <label class="col-6 pl-3">{{ $t('selectImgFromPC') }}
                  <input ref="fileInput" class="pt-2" type="file" accept="image/*"
                    @change="handleFileChange($event.target)" />
                </label>
                <label class="col-6 text-right pr-4" style="color: red;" @click="deleteLocalImg()"><i
                    class="fi fi-rr-trash"></i> {{
                      $t('deleteImg') }}
                </label>
              </div>
              <VueCropper
                v-if="currentImg !== '' && currentImg !== null && !currentImg.startsWith('https://firebasestorage')"
                :guides="true" :aspectRatio="1.3" :initialAspectRatio="1.3" ref="cropper2" :src="getCurrentImage()"
                alt="Source Image" :zoomable="false" :scalable="false">
              </VueCropper>
              <img v-else :src="getCurrentImage()" class="img-fluid"></img>


              <template #footer-wrapper>
                <CRow class=" p-4">
                  <CCol>
                    <CButton block color="success" v-if="loadingSaveimg === true" v-on:click="saveAdditionalImg">
                      {{ $t('save') }}
                    </CButton>
                    <CButton block color="success" v-else-if="loadingSaveimg === false" disabled>
                      <CSpinner color="white" size="sm" /> {{ $t('save') }}
                    </CButton>
                  </CCol>
                  <CCol>
                    <CButton block color="light" @click="additionalModal = false">
                      {{ $t('cancel') }}
                    </CButton>
                  </CCol>
                </CRow>
              </template>
            </CModal>
            <CRow>
              <CCol md="12" class="form-group row">
                <label class="col-sm-2 col-form-label text-right text-dark w-100 w-sm-auto">
                  <b class="text-danger font-weight-normal">*</b> {{ $t('productName') }}
                </label>
                <div class="col-sm-10">
                  <CInput v-model="SKUName" :placeholder="$t('enterProductName')" class="text-black"
                    required
                  />
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" class="form-group row">
                <label class="col-sm-2 col-form-label text-right text-dark w-100 w-sm-auto">
                  {{ $t('price') }}
                </label>
                <div v-if="validateSKUPrice" class="col-sm-10">
                  <CInput v-model.number="SKUPrice" :placeholder="$t('price')" min="0" type="number"
                    :is-valid="SKUPrice !== null && SKUPrice !== '' && SKUPrice >= 0"
                    :is-invalid="SKUPrice === null || SKUPrice === ''"
                    :invalid-feedback="$t('priceMustBeGreaterThanZero')" />
                </div>
                <div v-else class="col-sm-10">
                  <CInput v-model.number="SKUPrice" :placeholder="$t('price')" min="0" type="number"
                    :is-valid="SKUPrice !== null && SKUPrice !== '' && SKUPrice >= 0"
                    :is-invalid="SKUPrice === null || SKUPrice === ''"
                    :invalid-feedback="$t('priceMustBeGreaterThanZero')" />
                </div>

              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" class="form-group row">
                <label class="col-sm-2 col-form-label text-right text-dark w-100 w-sm-auto">
                  {{ $t('pluCode') }}
                </label>
                <div class="col-sm-10">
                  <CInput v-model="productcode" :placeholder="$t('pluCode')" :disabled="!isEditData" />
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" class="form-group row">
                <label class="col-sm-2 col-form-label text-right text-dark w-100 w-sm-auto">
                  <b class="text-danger">*</b> {{ $t('category') }}
                </label>
                <div class="col-sm-10">
                  <multiselect v-model="Category" :options="allCategories" :placeholder="$t('category')" label="name"
                    track-by="name" v-if="isEditData"></multiselect>
                  <input v-else v-model="category.name" class="form-control" disabled />
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" class="form-group row">
                <label class="col-sm-2 col-form-label text-right text-dark w-100 w-sm-auto">
                  <b class="text-danger">*</b> {{ $t('unit') }}
                </label>
                <div class="col-sm-10">
                  <multiselect v-model="unit" :options="unitlist" class="cursor" :placeholder="$t('unit')" label="name"
                    track-by="name" v-if="isEditData"></multiselect>
                  <input v-else v-model="unit.name" class="form-control" disabled />
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <!-- Product Type Properties -->
        <CCard class="shadow-sm" style="">
          <CCardBody>
            <div class="row">
              <div class="col-md-12 col-12">
                <h4 class="font-weight-normal text-dark">
                  {{ $t('productType') }}
                </h4>
              </div>
            </div>
            <CCol lg="12" md="12" sm="12" class="row justify-content-center">
              <CCol lg="8" md="6" sm="6" col="12">
                <CRow>
                  <CCol sm="6" col="6" class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input success cursor" v-model="vatType" id="vatType"
                        true-value="V" false-value="N" :disabled="!isEditData" />
                      <label class="custom-control-label cursor" for="vatType">
                        {{ $t('netTotalIncVAT') }}
                      </label>
                    </div>
                  </CCol>
                  <CCol sm="6" col="6" class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input success cursor" v-model="favorite"
                        id="favorite" :disabled="!isEditData" />
                      <label class="custom-control-label cursor" for="favorite">
                        {{ $t('favorite') }}
                      </label>
                    </div>
                  </CCol>
                  <CCol sm="6" col="6" class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input success cursor" v-model="serviceCharge"
                        id="serviceCharge" :disabled="!isEditData" />
                      <label class="custom-control-label cursor" for="serviceCharge">
                        {{ $t('serviceCharge') }}
                      </label>
                    </div>
                  </CCol>
                  <CCol sm="6" col="6" class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input success cursor" v-model="isOnScreen"
                        id="isOnScreen" :disabled="!isEditData" />
                      <label class="custom-control-label cursor" for="isOnScreen">
                        {{ $t('isOnScreen') }}
                      </label>
                    </div>
                  </CCol>
                  <CCol sm="6" col="6" class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input success cursor" v-model="negotiatePrice"
                        id="isnegotiatePrice" :disabled="!isEditData" />
                      <label class="custom-control-label cursor" for="isnegotiatePrice">
                        {{ $t('negotiatePrice') }}
                      </label>
                    </div>
                  </CCol>
                  <CCol sm="6" col="6" class="form-group" v-if="isMenuEnabled">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input success cursor" id="isMenu" v-model="isMenu"
                        :disabled="!isEditData" />
                      <label class="custom-control-label cursor" for="isMenu">
                        {{ $t('isMenu') }}</label>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CCol>
            <CCol lg="12" md="12" sm="12">
              <div class="form-group row">
                <label class="col-sm-2 col-4 col-form-label text-right text-dark  mt-3">
                  <b class="text-danger">*</b> {{ $t('productType') }}
                </label>
                <div class="col-sm-5 col-8  mt-3">
                  <select class="custom-select" v-model="skuType" disabled>
                    <option value="P" selected>
                      {{ $t('productTypeP') }}
                    </option>
                    <option value="BOM">
                      {{ $t('productTypeBOM') }}
                    </option>
                    <option value="SN">{{ $t('productTypeSN') }}</option>
                    <option value="SV">{{ $t('productTypeSV') }}</option>
                  </select>
                </div>
                <div class="col-sm-5 mt-3">
                  <CRow>
                    <CCol col="6" lg="6" class="mb-1">
                      <div v-if="skuType == 'P'">
                        <CButton class="btn btn-outline-success btn-block" @click="addProductPLU()">
                          {{ $t('productPLU') }} {{ PLUCountShow }}
                        </CButton>
                      </div>
                      <div v-if="skuType == 'BOM'">
                        <CButton class="btn btn-outline-success btn-block" @click="addProductBOM()">
                          {{ $t('productBOM') }} {{ BOMCountShow }}
                        </CButton>
                      </div>
                      <div v-if="skuType == 'SN'">
                        <CButton class="btn btn-outline-success btn-block" @click="addProductSerial()">
                          {{ $t('productSN') }}
                        </CButton>
                      </div>
                    </CCol>
                    <CCol col="6" lg="6">
                      <CButton class="btn btn-outline-success btn-block" @click="onclickChannels()">
                        {{ $t('Channels') }}
                      </CButton>
                    </CCol>
                  </CRow>
                </div>
              </div>
            </CCol>
          </CCardBody>
        </CCard>

        <!-- PRU Table -->
        <CCard class="shadow-sm" style="">
          <CCardBody>
            <div class="col-md-12 col-sm-12">
              <div class="row">
                <h4 class="col-md-10 col-sm-9 col-8 font-weight-normal">
                  {{ $t('morePrice') }} ({{ countSKU }})
                </h4>
                <div class="col-md-2 col-sm-3 col-4 text-right text-success ">
                  <CButton @click="addproductPRU()" block color="info" v-show="isEditData">
                    {{ $t('addPrice') }}
                  </CButton>
                </div>
              </div>
            </div>
            <hr />
            <table style="white-space:nowrap;" class="table table-responsive table-striped table-bordered ">
              <thead>
                <tr>
                  <th class="text-dark font-weight-normal" style="width: 10%">{{ $t('quantityPrice') }}</th>
                  <th class="text-dark font-weight-normal" style="width: 10%">{{ $t('cost') }}</th>
                  <th class="text-dark font-weight-normal" style="width: 10%">{{ $t('exceedUc') }}</th>
                  <th class="text-dark font-weight-normal" style="width: 10%">{{ $t('priceUnit') }}</th>
                  <th class="text-dark font-weight-normal" style="width: 2%"></th>
                </tr>
              </thead>
              <tbody v-if="loading == true">
                <tr v-for="(item, index) in rowslist" :key="index">
                  <td style="width: 10%">
                    <input type="number" :value="isNaN(item.packQty) ? 0 : item.packQty" disabled
                      class="form-control text-right" />
                  </td>
                  <td style="width: 10%">
                    <div v-if="index == 0">
                      <input min="0" v-model.number="stdCost" type="number"
                        :class="['form-control', 'text-right', { 'text-danger': item.stdCost > item.price && item.price > 0 }]"
                        :disabled="item.skuType == 'BOM'" />
                      <div class="d-flex justify-content-end">
                        <span v-if="item.stdCost > item.price && item.price > 0" class="text-danger text-right"
                          style="font-size: 10px;">
                          * {{ $t('stdCostWarning') }}
                        </span>
                      </div>
                    </div>
                    <div v-else>
                      <input v-model.number="item.stdCost"
                        :class="['form-control', 'text-right', { 'text-danger': item.stdCost > item.price && item.stdCost > 0 }]"
                        disabled />
                      <div class="d-flex justify-content-end">
                        <span v-if="item.stdCost > item.price && item.stdCost > 0" class="text-danger text-right"
                          style="font-size: 10px;">
                          * {{ $t('stdCostWarning') }}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td style="width: 10%">
                    <input v-if="index == 0" type="number" min="0" v-model.number="item.price"
                      @input="inputpackUc($event.target.value)" class="form-control text-right"
                      :disabled="!isEditData" />
                    <input v-else v-model.number="item.packUc" disabled class="form-control text-right" />
                  </td>
                  <td style="width: 10%">
                    <input v-if="index == 0" v-model="item.exceedUc" disabled style="background-color: #d8dbe0"
                      class="form-control text-right" />
                    <input v-model="item.exceedUc" v-else disabled class="form-control text-right" />
                  </td>
                  <td class="text-center" style="width: 3%">
                    <CRow v-if="index != 0">
                      <CCol sm="6" md="6" style="width: 3%">
                        <img src="/img/file-edit.png" @click="EditPRU(item)" v-show="isEditData" style="width: 14px; height: 14px; margin-right: 8px; cursor: pointer;"/>  
                      </CCol>
                      <CCol sm="6" md="6" style="width: 3%">
                        <img src="/img/trash.png" style="width: 14px; height: 14px; cursor: pointer; " @click="deletePRU(item.objectId)" v-show="isEditData">
                      </CCol>
                    </CRow>
                  </td>
                </tr>
              </tbody>
            </table>
          </CCardBody>
        </CCard>


        <CCard class="shadow-sm" style="">
          <CCardBody>
            <div class="col-md-12 col-sm-12">
              <div class="row">
                <h4 class="col-md-10 col-sm-9 col-8 font-weight-normal">
                  {{ $t('txtOptionCategory') }}
                </h4>
                <div class="col-md-2 col-sm-3 col-4 text-right text-success ">
                  <CButton @click="AddOptionGroupModal = true" block color="info" v-show="isEditData">
                    {{ $t('addOptionGroup') }}
                  </CButton>
                </div>
              </div>
              <hr />
              <div class="row mt-2">
                <CDataTable v-if="loading == true" :items="activeGroup" :fields="optionGroupFields" hover border
                  class="col-md-12 col-sm-12">

                  <template #action="{ item }">
                    <td class="text-center">
                      <CRow>
                        <CCol>
                          <img src="/img/trash.png" style="width: 14px; height: 14px; cursor: pointer; " v-if="isDeleteData" @click="confirmdeleteOptionGroup(item)">
                        </CCol>
                      </CRow>
                    </td>

                  </template>

                  <template #no-items-view>
                    <div class="text-center my-5">
                      <h4 style="color: #ced2d8;">
                        {{ $t('noItem') }}
                      </h4>
                    </div>
                  </template>
                </CDataTable>
              </div>
            </div>
          </CCardBody>
        </CCard>

        <CRow>
          <CModal :show.sync="AddOptionGroupModal" centered style="max-width: 915px;">

            <template #header-wrapper class="header-modal">
              <header class="modal-header header-modal">
                <h4 class="modal-title font-weight-normal">
                  {{ $t('selectdProduct') }}
                </h4>
                <button type="button" aria-label="Close" class="close" @click="AddOptionGroupModal = false">
                  ×
                </button>
              </header>
            </template>

            <template #body-wrapper>
              <CCardBody>
                <div style="max-height: 400px; overflow-y: auto;" class="mt-4">
                  <CDataTable :fields="fields" :items="optionGroupList" hover v-model="optionGroupListItem" border
                    clickableRows @row-clicked="onSelectedAutoCompleteEvent">
                    <template #selected="{ item, index }">
                      <td style="vertical-align: middle;">
                        <div class="custom-control custom-checkbox" style="padding-left: 2rem">
                          <input type="checkbox" class="custom-control-input success cursor" v-model="item.selected"
                            @change="onSelectedAutoCompleteEvent(item)" :id="index" />
                          <label class="custom-control-label cursor" :for="index">
                          </label>
                        </div>
                      </td>
                    </template>

                    <template #groupName="{ item }">
                      <td class="text-dark font-weight-normal" style="vertical-align: middle;border-left: hidden;">
                        {{ item.groupName }}
                      </td>
                    </template>

                    <template #no-items-view>
                      <div class="text-center my-5">
                        <h4 style="color: #ced2d8;">
                          {{ $t('noItem') }}
                        </h4>
                      </div>
                    </template>
                  </CDataTable>
                </div>
              </CCardBody>
            </template>

            <template #footer-wrapper>
              <CRow class="justify-content-end col-md-12">
                <CCol col="12" lg="6" class="row">
                  <div class="col-6">
                    <CButton class="btn btn-outline-secondary text-dark font-weight-normal"
                      style="color: black; width: inherit" @click="AddOptionGroupModal = false">
                      {{ $t('cancel') }}
                    </CButton>
                  </div>
                  <div class="col-6">
                    <CButton block color="success" v-on:click="onCheckboxChange">
                      {{ $t('confirm') }}
                    </CButton>
                    <!-- <CButton block color="success" disabled>
                      <CSpinner color="white" size="sm" /> {{ $t('save') }}
                    </CButton> -->
                  </div>
                </CCol>
              </CRow>
              <br />
            </template>
          </CModal>
        </CRow>
        <CRow>
          <CModal color="danger" :title="$t('confirmDeletGroup')" :show.sync="deleteOptionGroupModal" centered>
            <h4 class="mt-4 mb-4 text-center">{{ $t('dialigDeleteGroup') }}</h4>
            <template #footer>
              <CRow class="justify-content-around col-md-12">
                <CCol col="4">
                  <CButton v-if="loadingDeleteButton === false" @click="deleteOptionGroup(itemDelete)" color="danger"
                    block>
                    {{ $t('delete') }}
                  </CButton>
                  <CButton v-else-if="loadingDeleteButton === true" color="danger" block disabled>
                    <CSpinner color="white" size="sm" />
                  </CButton>
                </CCol>
                <CCol col="4">
                  <CButton v-if="loadingDeleteButton === false" @click="deleteModal = false" color="light" block>
                    {{ $t('cancel') }}
                  </CButton>
                  <CButton v-else-if="loadingDeleteButton === true" color="light" disabled block>
                    {{ $t('cancel') }}
                  </CButton>
                </CCol>
              </CRow>
            </template>
          </CModal>
        </CRow>
        <!-- Save Button & Cancel Button -->
        <div v-if="isEditData">
          <CRow class="justify-content-end">
            <CCol lg="2" col="6">
              <CButton
                style="background-color: white; font-weight: normal;margin-right: 15px;box-shadow: 0 0 0 1px #e5e5e5;"
                class="btn-block" @click="cancelUpdate()" square>
                {{ $t('cancel') }}
              </CButton>
            </CCol>
            <CCol lg="2" col="6">
              <CButton color="success" class="btn-block" style="" @click="validatePopup()" square>
                {{ $t('save') }}
              </CButton>
            </CCol>
          </CRow>
        </div> <!-- End Button  -->

      </CCol>
      <CRow>
        <CModal :closeOnBackdrop="false" color="success" :show.sync="selectcolor" :title="$t('selectImg')" centered>
          <div v-if="loadingSaveimg">
            <CRow>
              <CCol md="1" sm="1" col="2" style="margin: 3px" v-for="item in colorList" :key="item.value"
                :value="item.value">
                <CButton v-if="indexColor === item.value"
                  style="box-shadow: 0 0 5px 5px rgb(163, 222, 194); color: white"
                  :class="{ active: indexColor === item.value }" @click="setindexcolor(item.value)"
                  :style="{ backgroundColor: item.color }">
                  <i class="fi fi-rr-check"></i>
                </CButton>
                <CButton v-else :style="{ backgroundColor: item.color }" :class="{ active: indexColor === item.value }"
                  @click="setindexcolor(item.value)">
                  <CIcon>
                    <i class="fas fa-square fa-2x"></i>
                  </CIcon>
                </CButton>
              </CCol>
            </CRow>
            <hr />
            <div class="row">
              <div class="col-md-6 col-6 text-left">
                <label>{{ $t('selectImgFromPC') }}</label>
                <input type="file" ref="fileInput" accept="image/*" @change="uploadImage" />
              </div>
              <div v-if="imageDataBtn.length > 0" class="col-md-6 col-6 text-right">
                <CButton style="color: red;" @click="removeImage"> <i class="fi fi-rr-trash"></i> {{ $t('deleteImg') }}
                </CButton>
              </div>
              <div v-if="imageDataBtn.length > 0" class="col-md-12 text-center">
                <img v-if="isUpload" :src="imageData" alt="..." width="40%" @click="selectcolormodal" class="avatar" />
                <VueCropper v-if="!isUpload && !imageDataBtn.startsWith('https://firebasestorage')"
                  v-show="imageDataBtn" :guides="true" :aspectRatio="1.3" :initialAspectRatio="1.3" ref="cropper"
                  :src="imageDataBtn" alt="Source Image" :zoomable="false" :scalable="false">
                </VueCropper>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="text-center" width="100%">
              <button class="btn" type="button" disabled style="color: darkcyan">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{ $t('กำลังอัปโหลดรูปภาพ...') }}
              </button>
            </div>
          </div>

          <template #footer-wrapper>
            <CRow class="p-4">
              <CCol>
                <CButton block color="success" v-if="loadingSaveimg" @click="savecolorimg">
                  {{ $t('save') }}
                </CButton>
                <CButton block color="success" v-else disabled>
                  {{ $t('save') }}
                </CButton>
              </CCol>
              <CCol>
                <CButton block color="light" @click="cancel">
                  {{ $t('cancel') }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
      </CRow>
      <CRow>
        <CModal :show.sync="productPRU" :title="namemodal" centered>
          <small class="text-danger font-weight-normal" v-if="alertValidatePRU !== ''">
            {{ alertValidatePRU }}
          </small>
          <CRow>
            <CCol md="3">
              <span>{{ $t('quantityPrice') }}</span>
            </CCol>
            <CCol md="9">
              <CInput v-model.number="pru.packQty" type="number" min="1" :placeholder="$t('quantityPrice')" />
            </CCol>
            <CCol md="3">
              <span>{{ $t('cost') }}</span>
            </CCol>
            <CCol md="9">
              <CInput :placeholder="$t('cost')" type="number" v-model="pru.stdCost" :value="isNaN((pru.stdCost = pru.packQty * stdCost))
                ? 0
                : (pru.stdCost = pru.packQty * stdCost)
                " disabled />
            </CCol>
            <CCol md="3">
              <span>{{ $t('exceedUc') }}</span>
            </CCol>
            <CCol md="9">
              <CInput v-model.number="pru.packUc" type="number" min="0" :placeholder="$t('exceedUc')"
                :value="isNaN(packUctotal) ? 0 : packUctotal" />
            </CCol>
            <CCol md="3">
              <span>{{ $t('priceUnit') }}</span>
            </CCol>
            <CCol md="9">
              <input v-model.number="exceedUCtotal" @input="checkExceed($event)" type="number" min="0"
                :placeholder="$t('priceUnit')" class="form-control" style="background-color: #d8dbe0" />
            </CCol>
          </CRow>

          <template #footer-wrapper>
            <CRow class="p-4">
              <CCol>
                <CButton v-if="loadingButtonValidatePRU === false" color="success" v-on:click="validatePRU" block>
                  {{ $t('save') }}
                </CButton>
                <CButton v-else color="success" block disabled>
                  <CSpinner color="white" size="sm" /> {{ $t('save') }}
                </CButton>
              </CCol>
              <CCol>
                <CButton v-if="loadingButtonValidatePRU === false" color="light" @click="productPRU = false" block>
                  {{ $t('cancel') }}
                </CButton>
                <CButton v-else color="light" block disabled>
                  {{ $t('cancel') }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
      </CRow>
      <CRow>
        <CModal color="warning" :show.sync="validationModal" :title="$t('dialogfound')" centered>
          <CRow>
            <CCol class="text-center mt-2" style="font-size: 24px;">
              <p v-if="validateSKUName" class="text-warning">{{ validateSKUName }}</p>
              <p v-if="validateSKUPrice" class="text-warning">{{ validateSKUPrice }}</p>
            </CCol>
          </CRow>
          <template #footer-wrapper>
            <div class="text-center mb-3">
              <CButton color="warning" @click="validationModal = false" class="text-white" style="width: 30%">{{
                $t('submit') }}</CButton>
            </div>
          </template>
        </CModal>
      </CRow>
      <CRow>
        <modal color="danger" :show.sync="deleteModal" :title="$t('confirmDeleteProduct')" v-on:isSave="deleteProduct"
          centered :loading="loading">
          <br />
          <h4 class="text-center">{{ $t('dialigDeleteProduct') }}</h4>
          <br />
        </modal>
      </CRow>
      <modal color="success" :show.sync="confirmModal" :title="$t('confirmSaveImg')" v-on:isSave="savedata" centered
        :alertError="alertError" :loading="loading">
        <div v-if="alertError">
          <h4 class="text-center text-danger">{{ textError }}</h4>
          <br />
          <h5 class="text-center text-danger">{{ textMessage }}</h5>
        </div>
        <div v-else>
          <br />
          <h4 class="text-center">{{ $t('confirmSaveImgDialog') }}</h4>
          <br />
          <div v-if="loading == false">
            <CProgress class="mb-3">
              <CProgressBar color="success" variant="striped" animated :value="uploadProgress" />
            </CProgress>
            <p class="text-center">{{ onUploadText }}</p>
          </div>

        </div>
      </modal>
      <CModal :show.sync="alertModal" color="danger" :title="namemodal" centered>
        <div>
          <br />
          <h4 class="text-center">{{ textError }}</h4>
          <br />
        </div>

        <template #footer>
          <CRow class="row justify-content-end">
            <CButton color="light" col="6" lg="4" md="4" block @click="alertModal = false">
              {{ $t('Close') }}
            </CButton>
          </CRow>
        </template>
      </CModal>
    </form> <!-- end Loaded data -->
  </div>
</template>

<script>
import util from '@/util/util'
import { mapGetters } from 'vuex'
import pos from '@/services/local'
import Multiselect from 'vue-multiselect'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import permis from '@/util/permission'
import uploadImage from '@/util/uploadImage'
import mime from '@/util/mime'
import router from '../../router'
import { relativeTimeThreshold } from 'moment'
import { cibTheMovieDatabase } from '@coreui/icons'
import { LazyYoutube } from "vue-lazytube";

export default {
  components: {
    Multiselect,
    VueCropper,
    LazyYoutube,

  },
  data() {
    return {
      selectedFileName: '',
      testModal: false,
      AddOptionGroupModal: false,
      deleteOptionGroupModal: false,
      loadingDeleteButton: false,
      optionGroupListItem: [],
      selectedItems: [],
      dataGroup: [],
      addList: [],
      selected: false,
      rows: [],
      SKU: [],
      indexColor: 1,
      unitAdd: [],
      namemodal: '',
      price: 0,
      serviceCharge: '',
      vatType: '',
      favorite: '',
      isOnScreen: '',
      amount: 1,
      SKUPrice: 0,
      SKUName: '',
      productcode: '',
      stdCost: 0,
      previewImage: null,
      imageData: '',
      imageName: '',
      imagePath: '',
      skuType: '',
      productPRU: false,
      name: '',
      detail: [],
      countSKU: 0,
      unit: [],
      dataUnit: [],
      Category: [],
      isSuccess: false,
      selectcolor: false,
      imageDataBtn: '',
      categories: [],
      itemid: '',
      confirmModal: false,
      addMoreUnitModal: false,
      colori: util.generateColor(1),
      shop: this.$store.getters.shopObjectId,
      itemObjectId: '',
      unitSelectcolor: false,
      pru: {
        exceedUc: 0,
        packQty: 0,
        packUc: 0,
        stdCost: 0,
      },
      deleteModal: false,
      loading: true,
      loadingSaveimg: true,
      negotiatePrice: '',
      isUpload: true,
      isload: false,
      popupModal: false,
      validateunit: '',
      validateSKUName: '',
      validateSKUPrice: '',
      validateproductcode: '',
      validatecategory: '',
      validateShop: '',
      validateAlert: '',
      textError: '',
      textMessage: '',
      alertError: false,
      isError: 0,
      isMenu: false,
      alertModal: false,
      PLUCountShow: '',
      BOMCountShow: '',
      imgTmpPath: '',
      alertValidatePRU: '',
      loadingButtonValidatePRU: false,
      resizedImageData: '',
      isLoadingProduct: true,
      additionImageAmount: parseInt(process.env.VUE_APP_ADDITIONAL_IMAGE),
      localAdditionImagePath: new Array(this.additionImageAmount),
      additionalModal: false,
      currentImg: '',
      currentButton: 0,
      trackingOldImagePath: [],
      additionImagePath: new Array(this.additionImageAmount),
      optionGroupActive: [],
      optionGroupdata: [],
      uploadVideoModal: false,
      videoOption: '',
      currentVideo: '',
      currentVideoHolder: '',
      maxFileSize: 20971520, //byte
      uploadProgress: 0,
      onUploadText: '',
      trackingOldVideoPath: '',
      trackingFirebaseItem: [],
      validationModal: false,
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    isSelfOrderEnabled() {
      return permis.findPermissionRead('selfOrder', this.$route.path)
    },
    isPermission() {
      let path = '/product'
      return permis.findPermissionRead('product', path)
    },
    isEditData() {
      let path = '/product'
      const value = permis.findPermissionEdit('product', path)
      return value //&& this.editItem
    },
    editItem() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      return shop.editableItem || false
    },
    isDeleteData() {
      let path = '/product'
      return permis.findPermissionRemove('product', path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    productSKUObjectId() {
      return this.$route.params.productSKUObjectId
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      return shop.uid
    },
    colorList() {
      let data = []
      for (let i = 0; i < 30; i++) {
        data.push({
          color: util.generateColor(i),
          value: i,
        })
      }
      return data
    },
    exceedUCtotal: {
      get() {
        let data = this.pru.packUc / this.pru.packQty
        return data
      },
      set() {
        let data = this.pru.packUc / this.pru.packQty
        return data
      },
    },
    packUctotal() {
      let data = this.pru.packUc * this.pru.packQty
      return data
    },
    allCategories() {
      const data = this.categories || []
      let objects = []
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        objects.push({
          id: item.id,
          name: item.name,
          objectId: item.objectId,
        })
      }
      return objects
    },
    // catagoryItem(){
    //   const data = this.categoriesName || []
    //   let objects = []
    //   for (let i = 0; i < data.length; i++){
    //     const item = data[i]
    //     objects.push({
    //       name: item.Catagory.name
    //     })
    //   }
    // },
    unitlist() {
      let data = this.dataUnit
      let detail = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].enabled === true) {
          detail.push({
            id: data[i].id,
            name: data[i].name,
            objectId: data[i].objectId,
          })
        }
      }
      return detail.sort(util.compare)
    },
    fields() {
      return [
        {
          key: 'selected',
          label: this.$i18n.t('selectd'),
          _style: 'width:10%; vertical-align: middle;',
          _classes: 'text-center font-weight-normal text-dark',
        },
        {
          key: 'name',
          label: this.$i18n.t('txtOptionGroup'),
          _style: 'width:75%; vertical-align: middle;border-left: hidden',
          _classes: 'text-left font-weight-normal text-dark',
        }
      ]
    },
    optionGroupList() {
      let data = this.optionGroupdata;
      let objects = [];
      let dataActive = this.optionGroupActive;
      for (let i = 0; i < data.length; i++) {
        const object = data[i];
        let item;
        if (dataActive.some(active => active.groupActiveObjectId === object.optionGroupObjectId)) {
          continue;
        }
        item = {
          name: object.optionGroupName,
          objectId: object.optionGroupObjectId,
          id: object.optionGroupId,
          selected: false // Default value is false if condition is not met
        }

        objects.push(item);
      }
      return objects;
    },
    activeGroup() {
      const object = []
      for (let i = 0; i < this.optionGroupActive.length; i++) {
        let item = {
          num: i + 1,
          groupActiveName: this.optionGroupActive[i].groupActiveName,
          groupActiveObjectId: this.optionGroupActive[i].groupActiveObjectId,
          groupActiveId: this.optionGroupActive[i].groupActiveId,
          action: '',
          activeObjectId: this.optionGroupActive[i].activeObjectId,
          activeId: this.optionGroupActive[i].activeId,
        };
        object.push(item);
      }
      return object
    },
    optionGroupFields() {
      return [
        {
          key: 'num',
          label: '#',
          _style: 'width:15%',
          _classes: 'text-center text-dark font-weight-normal'
        },
        {
          key: 'groupActiveName',
          label: this.$i18n.t('category'),
          _style: 'width:70%',
          _classes: 'text-dark font-weight-normal'
        },
        {
          key: 'action',
          label: '',
          _style: 'width:15%',
          _classes: 'text-dark font-weight-normal'
        },


      ]
    },
    rowslist() {
      let data = this.rows
      let detail = []
      let cost = parseFloat(this.stdCost)
      let stdCost = typeof cost == 'number' ? parseFloat(cost) : 0
      if (data.length > 0) {
        data.forEach((e) => {
          detail.push({
            id: e.id,
            objectId: e.objectId,
            packQty: e.packQty,
            price: (e.packUc),
            packUc: util.convertCurrency(e.packUc),
            exceedUc: util.convertCurrency(e.exceedUc),
            stdCost: typeof stdCost == 'number' ? (stdCost * e.packQty).toFixed(2) : stdCost,
            skuType: e.skuType
          })
        })
      } else {
        detail.push({
          id: '',
          objectId: this.productSKUObjectId,
          packQty: 1,
          exceedUc: util.convertCurrency(this.SKUPrice),
          stdCost: typeof stdCost == 'number' ? (stdCost).toFixed(2) : stdCost,
          price: this.SKUPrice,
          packUc: this.SKUPrice,
          skuType: this.skuType
        })
      }
      return detail
    },
    showView() {
      return this.$route.query.view || 'grid'
    },
    sortdata() {
      return this.$route.query.sort || 'SKUName'
    },
    page() {
      return this.$route.query.page || 1
    },
    searchBy() {
      return this.$route.query.searchBy || ''
    },
    keyword() {
      return this.$route.query.keyword || ''
    },
    queryURL() {
      return (
        '?sort=' +
        this.sortdata +
        '&page=' +
        this.page +
        '&view=' +
        this.showView +
        '&searchBy=' +
        this.searchBy +
        '&keyword=' +
        this.keyword
      )
    },
    isMenuEnabled() {
      if (this.shop) {
        if (this.shop.isMenuEnabled) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    isSelfOrderEnabled() {
      const shop = this.shop || {}
      const delivery = shop.delivery || {}
      const enabled = delivery.isSelfOrderEnabled || false
      return enabled
    },
  },
  async created() {
    await this.getProduct()
    await this.getCategory()
    await this.getUnit()
    await this.getProductPRU()
    await this.getOptionGroup()
    await this.getProductOption()
    this.shop = this.shops.find((i) => i.objectId === this.shop)
  },
  mounted() {
    document.addEventListener('keydown', this.doSave)
  },
  watch: {
    SKUPrice(newValue) {
      if (this.rowslist[0].price !== newValue) {
        this.rowslist[0].price = newValue;
      }
    },
    // 'rowslist[0].price': function(newValue) {
    //   if (this.SKUPrice !== newValue) {
    //     this.SKUPrice = newValue;
    //   }
    // }
  },
  methods: {
    validator(val) {
      return val ? val.length >= 1 : false
    },
    selectVideo() {
      this.uploadVideoModal = true
    },
    selectAdditionalImg(index) {
      this.currentButton = index
      if (this.localAdditionImagePath[index] !== undefined) {
        this.currentImg = this.localAdditionImagePath[index]
      } else {
        this.currentImg = ''
      }

      this.additionalModal = true
    },
    pickFile() {
      this.$refs.image.click()
    },
    async saveAdditionalImg() {
      let cropedImage = this.$refs.cropper2.getCroppedCanvas().toDataURL()
      const resizedImage = await this.resizeImage(cropedImage)
      if (this.localAdditionImagePath.length <= this.additionImageAmount + 1) {
        this.localAdditionImagePath[this.currentButton] = resizedImage
      } else {
        alert('Out of index')
      }
      this.$refs.cropper2.destroy()
      this.currentImg = ''
      this.additionalModal = false
    },
    async getImageURL2() {
      try {
        for (let i = 0; i <= this.additionImageAmount; i++) {
          if (this.localAdditionImagePath[i] && !this.localAdditionImagePath[i].startsWith("https://firebasestorage")) {
            let ext = mime.getExtension(this.localAdditionImagePath[i]);
            if (ext == null) {
              this.isload = false;
            } else {
              const imageName = util.generateFilename('.' + ext);
              const ownerUID = localStorage.getItem('uid');
              let ref = this.productSKUObjectId
              const directoryPath = 'resources/' + ownerUID + '/resources/products/' + ref + '/' + imageName;
              this.onUploadText = this.$i18n.t('imageUploading')
              this.uploadProgress = 66
              const uploadedImagePath = await uploadImage.onUpload2(directoryPath, this.localAdditionImagePath[i]);
              this.additionImagePath.push(uploadedImagePath);
            }
          } else if (this.localAdditionImagePath[i] != null) {
            this.uploadProgress = 66
            this.additionImagePath.push(this.localAdditionImagePath[i]);
          }
        }
      } catch (error) {
        console.error("Error during getImageURL2:", error);
      }
    },

    getCurrentImage() {
      return this.currentImg
    },
    saveVideo() {
      this.currentVideo = this.currentVideoHolder
      this.uploadVideoModal = false
    },
    delteVideo() {
      this.videoOption = ''
      this.currentVideo = ''
      this.currentVideoHolder = ''
    },

    async blobToDataURL(blobUrl) {
      const response = await fetch(blobUrl);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },

    async uploadVideoToFirebase() {
      const ownerUID = `${localStorage.getItem('uid')}`;

      if (this.videoOption == 'Upload') {
        this.onUploadText = this.$i18n.t('videoUploading')
        this.uploadProgress = 30
        let videoDataUrl = await this.blobToDataURL(this.currentVideoHolder)
        let upload = await uploadImage.onUpload2('resources/' + ownerUID + '/resources/products/' + this.productSKUObjectId + '/video001', videoDataUrl)
        this.currentVideo = upload
      } else {
        const uid = `${localStorage.getItem('shopsUid')}`
        const shopObjectId = this.shopObjectId
        await uploadImage.deleteImage(uid, shopObjectId, this.trackingOldVideoPath)
      }
    },
    deleteLocalImg() {
      this.trackingOldImagePath.push(this.localAdditionImagePath[this.currentButton])
      this.localAdditionImagePath[this.currentButton] = null
      this.$refs.cropper2.destroy()
      this.currentImg = ''
    },
    handleVideoFileChange: function (event) {
      this.currentVideoHolder = '';
      let file = event.files[0];

      if (file.size <= this.maxFileSize) {
        this.$nextTick(() => {
          this.currentVideoHolder = window.URL.createObjectURL(file)
        })
      } else {
        event.target = '';
        alert(this.$i18n.t('bigVideoAlert'))
      }
    },
    handleFileChange: function (event) {
      this.isUpload = true
      this.currentImg = '';
      let file = event.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (e) => {
        this.currentImg = e.target.result;
        this.$refs.cropper2 = this.currentImg
        this.isUpload = false
        this.$refs.fileInput.value = '';
      };
    },
    noImgUrl(event) {
      event.target.src = process.env.VUE_APP_NOIMAGE
    },
    goback() {
      this.$router.push('/product' + this.queryURL)
    },
    inputSKUPrice(value) {
      this.rowslist[0].price = util.currencyStringToNumber(value)
      this.rowslist[0].exceedUc = util.convertCurrency(value)
    },
    inputpackUc(value) {
      this.SKUPrice = util.currencyStringToNumber(value)
      this.rowslist[0].exceedUc = util.convertCurrency(value)
    },
    doSave(e) {
      if (this.$router.app._route.name == 'ProductDetail') {
        if (e.key === 'Enter' && e.target.nodeName === 'INPUT') {
          //check keycode enter จาก input form
          var focusableElementsString =
            'a[href], area[href], input:not([disabled]),select ,select:not([disabled]), textarea:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]'
          let arr = Array.from(
            e.target.form.querySelectorAll(focusableElementsString)
          ) // เก็บ input ทะ้งหมดที่อยู่ใน from เป็น array
          for (let i = 0; i < arr.length; i++) {
            if (arr[i] === e.target) {
              let indexOf = i < arr.length - 1 ? arr[i + 1] : indexOf[0] //array จาก form input
              indexOf.focus()
              break //ไปที่ input ลำดับต่อไป
            }
          }
          e.preventDefault()
        }
        if (!(e.keyCode === 83 && e.ctrlKey)) {
          return
        } else if (e.keyCode === 83 && e.ctrlKey) {
          this.validatePopup()
        }
        e.preventDefault()
      }
    },
    checkExceed(event) {
      this.pru.packUc = event.target.value * this.pru.packQty
    },
    addRow() {
      this.rows.push({})
    },
    confirmdelete() {
      this.deleteModal = true
    },
    deleteProduct() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const plan = this.users.currentPlan
      const shopObjectId = this.shopObjectId
      let data = {
        shopObjectId: shopObjectId,
        objectId: this.productSKUObjectId,
      }
      const headers = { shopObjectId: shopObjectId }

      pos({
        method: 'post',
        url: '/api/v1.0/' + uid + '/ProductSKU/softdelete',
        params: { plan: plan },
        data: data,
        headers: headers,
      })
        .then(
          setTimeout(() => {
            this.deleteModal = false
            this.$router.go(-1)
          }, 100)
        )
        .catch((error) => {
          console.log(error)
        })
    },
    addproductPRU() {
      this.itemid = ''
      this.itemObjectId = ''
      this.pru.packQty = 1
      this.pru.packUc = 0
      this.pru.exceedUc = 0
      this.namemodal = this.$i18n.t('addPrice')
      this.productPRU = true
    },
    EditPRU(item) {
      this.alertValidatePRU = ''
      this.itemid = item.id
      this.itemObjectId = item.objectId
      this.pru.packQty = item.packQty
      this.pru.packUc = util.currencyStringToNumber(item.packUc)
      this.namemodal = this.$i18n.t('editPrice')
      this.productPRU = true
    },
    setindexcolor(index) {
      this.indexColor = index
      this.colori = util.generateColor(index)
      this.imageDataBtn = ''
      this.selectcolor = false
    },
    deletePRU(item) {
      const uid = `${localStorage.getItem('shopsUid')}`
      const plan = this.users.currentPlan
      const shopObjectId = this.shopObjectId
      this.loading = false
      let data = {
        shopObjectId: shopObjectId,
        objectId: item,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      pos({
        method: 'post',
        url: '/api/v1.0/' + uid + '/ProductPRU/softdelete',
        params: { plan: plan },
        data: data,
        headers: headers,
      })
        .then(
          setTimeout(() => {
            this.loading = true
            this.getProductPRU()
          }, 100)
        )
        .catch((error) => {
          console.log(error)
        })
    },
    async removeImage() {
      // if (this.remoteImagePath != '') {
      //   await uploadImage.deleteImage(
      //     this.uid,
      //     this.shopObjectId,
      //     this.remoteImagePath
      //   )
      // }
      this.imageData = ''
      this.imageDataBtn = ''
      this.remoteImagePath = ''
      this.isload = false
      this.selectcolor = false
    },
    selectcolormodal() {
      this.isUpload = true
      this.selectcolor = true
    },
    cancel() {
      if (this.remoteImagePath != undefined) {
        this.imageDataBtn = this.remoteImagePath
        this.selectcolor = false
        this.isUpload = true
        this.imageData = this.remoteImagePath
      }
      this.selectcolor = false
      this.isload = false
    },
    cancelUpdate() {
      if (this.imgTmpPath != '') {
        this.removeImage()
      }
      this.$router.push('/product' + this.queryURL)
    },
    pickFile() {
      this.$refs.image.click()
    },
    savecolorimg() {
      this.loadingSaveimg = false
      if (this.isload) {
        let cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
        this.imageData = cropedImage
        this.getImageURL()
      } else {
        this.imageData = ''
        this.remoteImagePath = ''
        this.selectcolor = false
        this.loadingSaveimg = true
      }
    },
    async getImageURL() {
      let ref = this.productSKUObjectId
      if (this.$refs.cropper.getCroppedCanvas() === undefined) {
        return
      }

      const getCroppedCanvas = this.$refs.cropper.getCroppedCanvas().toDataURL()
      const resizedImage = await this.resizeImage(getCroppedCanvas)
      let ext = await mime.getExtension(resizedImage)
      if (ext == null) {
        alert('Only Support File Type Is JPEG, PNG')
        this.isload = false
      } else {
        const oldImagePath = this.imagePath
        await uploadImage.deleteImage2(oldImagePath)

        const imageName = util.generateFilename('.' + ext)
        const ownerUID = `${localStorage.getItem('uid')}`
        const directoryPath = 'resources/' + ownerUID + '/resources/products/' + ref + '/' + imageName

        // this.remoteImagePath = await uploadImage.onUpload(ownerUID, ref, resizedImage, ext)
        this.remoteImagePath = await uploadImage.onUpload2(directoryPath, resizedImage)

        this.imgTmpPath = this.remoteImagePath
        this.imagePath = directoryPath
        this.selectcolor = false
        this.loadingSaveimg = true
      }
    },
    uploadImage(e) {
      const file = e.target.files[0]
      if (typeof FileReader === 'function') {
        this.dialog = true

        const reader = new FileReader()
        reader.onload = (event) => {
          this.imageDataBtn = event.target.result
          this.$refs.cropper = this.imageData
          this.isUpload = false
          this.isload = true
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    async resizeImage(base64Str, maxWidth = 650, maxHeight = 500) {
      return new Promise((resolve) => {
        let img = new Image()
        img.src = base64Str
        img.onload = () => {
          let canvas = document.createElement('canvas')
          const MAX_WIDTH = maxWidth
          const MAX_HEIGHT = maxHeight
          let width = img.width
          let height = img.height

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width
              width = MAX_WIDTH
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height
              height = MAX_HEIGHT
            }
          }
          canvas.width = width
          canvas.height = height
          let ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)
          resolve(canvas.toDataURL())
        }
      })
    },
    validatePRU() {
      let newPRU = this.rowslist.filter((item) => {
        if (
          item.packUc == this.pru.packUc &&
          item.packQty == this.pru.packQty &&
          item.objectId != this.itemObjectId
        ) {
          return true
        }

        return false
      })

      if (this.pru.packQty == 0 || this.pru.packQty == '') {
        this.alertValidatePRU = this.$i18n.t('validatepackQty')
      }
      if (this.pru.packUc < 0) {
        this.alertValidatePRU = this.$i18n.t('validatepackUc')
      }

      if (this.pru.packQty != 0 && this.pru.packUc >= 0) {
        if (newPRU.length == 0) {
          this.loadingButtonValidatePRU = true
          this.alertValidatePRU = ''
          this.saveProductpru()
        } else {
          this.alertValidatePRU = this.$i18n.t('validatePRU')
        }
      }
    },
    validatePopup() {
      this.validateSKUName = ''
      this.validateSKUPrice = ''
      this.validateAlert = ''
      if (this.SKUName === '') {
        this.validateAlert = this.$i18n.t('enterProduct')
        this.validateSKUName = this.$i18n.t('enterProduct')
        this.validationModal = true
        return
      } else {
        this.validateSKUName = ''
      }
      if (this.SKUPrice < 0 || this.SKUPrice === '') {
        this.validateAlert = this.$i18n.t('priceMustBeGreaterThanZero')
        this.validateSKUPrice = this.$i18n.t('priceMustBeGreaterThanZero')
        this.validationModal = true
        return
      } else {
        this.validateSKUPrice = ''
      }
      this.validateAlert = ''

      if (this.productcode == '') {
        this.productcode = util.generateBarcode()
      }

      if (this.Category == '') {
        this.validateAlert = this.$i18n.t('selectCategory')
        this.validationModal = true
        return
      }
      if (this.unit == '' || this.unit === undefined) {
        this.validateAlert = this.$i18n.t('selectUnit')
        this.validationModal = true
        return
      }

      if (this.SKUPrice !== this.rowslist[0].price) {
        this.validateSKUPrice = this.$i18n.t('priceMustBeGreaterThanZero')
        this.validationModal = true
        return
      }

      this.alertError = false
      this.loading = true
      this.confirmModal = true
    },
    async getUnit() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const headers = {
          shopObjectId: shopObjectId,
      }
      let params = { page: 1, limit: 1000 }
      try {
        const URL = '/api/v1.0/uid/' + uid + '/Unit/data'
        const res = await pos.get(URL, { params,headers })
        this.dataUnit = res.data.data
      } catch (error) {
        console.log(error)
      }

    },
    async getCategory() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      let params = { page: 1, limit: 1000 }
      const headers = {
                shopObjectId: shopObjectId,
            }
      try {
        const URL = '/api/v1.0/shop/' + uid + '/Category/data/' + shopObjectId
        const res = await pos.get(URL, { params ,headers})
        this.categories = res.data.data
      } catch (error) {
        console.log(error)
      }
    },
    // async getCategoryItem() {
    //   const uid = `${localStorage.getItem('shopsUid')}`
    //   const objectId = this.productSKUObjectId
    //   let params = { page: 1, limit: 1000, objectId: this.productSKUObjectId }
    //   try {
    //     const URL = '/api/v1.0/' + uid + '/productsku/getwithcategory/' 
    //     const res = await pos.get(URL, { params })
    //     this.categoriesName = res.data.data.Category.name
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    async getProduct() {
      this.loading = false
      this.isLoadingProduct = true
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      let params = {
        shopObjectId: shopObjectId,
        categoryObjectId: '',
        objectId: this.productSKUObjectId
      }

      try {
        const headers = { shopObjectId: shopObjectId }
        const res = await pos({
          url: '/api/v1.0/' + uid + '/productsku/getwithcategory/',
          params: params,
          headers: headers,
          method: 'GET',
        })

        const data = res.data.data

        if (data.remoteVideoPath != undefined && data.remoteVideoPath != '') {
          this.trackingFirebaseItem.push(data.remoteVideoPath)
          this.trackingOldVideoPath = data.remoteVideoPath
          this.currentVideo = data.remoteVideoPath
          this.currentVideoHolder = data.remoteVideoPath
          if (this.currentVideoHolder.startsWith("https://firebasestorage")) {
            this.videoOption = 'Upload'
          } else {
            this.videoOption = 'URL'
          }
        }

        this.data = data
        this.isLoadingProduct = false
        if (data.additionImagePath != undefined) {
          this.localAdditionImagePath = data.additionImagePath
          data.additionImagePath.forEach(element => {
            this.trackingFirebaseItem.push(element)
          });
        }
        this.SKU = this.data
        this.SKUName = data.SKUName
        this.SKUPrice = parseFloat(data.SKUPrice)
        if (data.ProductPLU == undefined) {
          this.productcode = ''
        } else {
          this.productcode = data.ProductPLU.PLUCode
        }
        this.indexColor = data.indexColor
        this.isOnScreen = data.isOnScreen
        this.favorite = data.favorite
        this.vatType = data.vatType
        this.serviceCharge = data.serviceCharge
        this.skuType = data.skuType
        this.imagePath = data.imagePath
        if (data.stdCost == undefined) {
          this.stdCost = 0
        } else {
          this.stdCost = parseFloat(data.stdCost).toFixed(2);
        }
        this.Category = data.Category
        this.unit = data.unit
        this.colori = util.generateColor(data.indexColor)
        if (
          this.data.remoteImagePath != undefined &&
          this.data.remoteImagePath != ''
        ) {
          this.trackingFirebaseItem.push(data.remoteImagePath)
          this.imageData = data.remoteImagePath
          this.imageDataBtn = data.remoteImagePath
          this.remoteImagePath = data.remoteImagePath
          this.$refs.cropper = data.remoteImagePath
        }
        this.negotiatePrice = data.negotiatePrice
        
        if (this.data.isMenu !== null) {
          this.isMenu = data.isMenu
        }

        if (data.skuType === 'P') {
          this.getPLUlist()
        } else if (data.skuType === 'BOM') {
          this.getBOMList()
        }
        this.loading = true
      } catch (error) {
        console.log(error)
      }
    },
    async savedata() {
      this.loading = false
      if (!this.currentVideo.startsWith("https://firebasestorage")) {
        await this.uploadVideoToFirebase()
      }


      let category = {
        id: this.Category.id,
        objectId: this.Category.objectId,
        name: this.Category.name
      }
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const plan = this.users.currentPlan

      this.additionImagePath.shift()
      await this.getImageURL2()
      let data = []
      if (this.imageData == '') {
        const oldImagePath = this.imagePath
        this.imagePath = ''
        uploadImage.deleteImage2(oldImagePath)
      }

      //Delete Additional Image 
      this.trackingOldImagePath.forEach(element => {
        let deleteImg = uploadImage.deleteImage(uid, shopObjectId, element)
      });

      if (this.remoteImagePath != '') {
        data = {
          objectId: this.productSKUObjectId,
          SKUName: this.SKUName,
          SKUPrice: this.SKUPrice,
          category: category,
          categoryId: this.Category.id,
          enabled: true,
          favorite: this.favorite,
          indexColor: this.indexColor,
          isOnScreen: this.isOnScreen,
          productPLU: { PLUCode: this.productcode },
          serviceCharge: this.serviceCharge,
          shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
          },
          shopId: this.shop.id,
          skuType: this.skuType,
          stdCost: parseFloat(this.stdCost),
          unit: {
            id: this.unit.id,
            name: this.unit.name,
            objectId: this.unit.objectId,
          },
          unitId: this.unit.id,
          remoteImagePath: this.remoteImagePath,
          imagePath: this.imagePath,
          vatType: this.vatType,
          negotiatePrice: this.negotiatePrice,
          'additionImagePath': this.additionImagePath,
          'remoteVideoPath': this.currentVideo,

        }
      } else {
        data = {
          objectId: this.productSKUObjectId,
          SKUName: this.SKUName,
          SKUPrice: this.SKUPrice,
          category: {
            id: this.Category.id,
            name: this.Category.name,
            objectId: this.Category.objectId,
          },
          categoryId: this.Category.id,
          enabled: true,
          favorite: this.favorite,
          indexColor: this.indexColor,
          isOnScreen: this.isOnScreen,
          productPLU: { PLUCode: this.productcode },
          serviceCharge: this.serviceCharge,
          shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
          },
          shopId: this.shop.id,
          skuType: this.skuType,
          stdCost: parseFloat(this.stdCost),
          unit: {
            id: this.unit.id,
            name: this.unit.name,
            objectId: this.unit.objectId,
          },
          unitId: this.unit.id,
          vatType: this.vatType,
          remoteImagePath: '',
          imagePath: this.imagePath,
          negotiatePrice: this.negotiatePrice,
          'additionImagePath': this.additionImagePath,
          'remoteVideoPath': this.currentVideo,
        }
      }
      if (this.shop.isMenuEnabled) {
        Object.assign(data, { isMenu: this.isMenu })
      }
      const headers = { shopObjectId: shopObjectId }

      this.uploadProgress = 90
      this.onUploadText = this.$i18n.t('productDataSaving')
      pos({
        method: 'post',
        url: '/api/v1.0/' + uid + '/productsku/edit',
        params: { shopObjectId: shopObjectId, plan: plan },
        data: data,
        headers: headers,
      })
        .then((response) => {
          setTimeout(() => {
            if (response.data.error.code === 4000) {
              this.textError = this.$i18n.t('failedToSave')
              this.textMessage = response.data.error.message
              this.alertError = true
              this.loading = true
            } else {
              this.loading = true
              this.confirmModal = false
              this.isSuccess = true
              this.$router.push('/product' + this.queryURL)
            }
          }, 1000)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    saveProductpru() {
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      const uid = `${localStorage.getItem('shopsUid')}`
      let data = {
        enabled: true,
        exceedUc: this.pru.packUc / this.pru.packQty,
        packQty: this.pru.packQty,
        packUc: this.pru.packUc,
        productSKU: {
          SKUName: this.SKUName,
          SKUPrice: this.SKUPrice,
          id: this.SKU.id,
          objectId: this.SKU.objectId,
        },
        productSKUId: this.SKU.id,
        shop: {
          objectId: this.shop.objectId,
          id: this.shop.id,
          branchName: this.shop.branchName,
        },
        shopId: this.shop.id,
        stdCost: this.stdCost * this.pru.packQty, //ต้นทุน
        negotiatePrice: this.negotiatePrice,
      }
      if (this.itemObjectId !== '') {
        data['id'] = this.itemid
        data['objectId'] = this.itemObjectId
      }

      const headers = { shopObjectId: this.shopObjectId }

      this.loading = false
      pos({
        method: 'post',
        url: '/api/v1.0/' + uid + '/productpru/add',
        data: data,
        headers: headers,
      })
        .then((response) => {
          setTimeout(() => {
            this.productPRU = false
            this.getProductPRU()
            this.loading = true
            this.loadingButtonValidatePRU = false
          }, 1000)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async getProductPRU() {
      const uid = `${localStorage.getItem('shopsUid')}`
      let params = { skuObjectId: this.productSKUObjectId }
      this.loading = false

      try {
        const res = await pos.get('/api/v1.0/' + uid + '/productpru/getbysku', { params })
        this.rows = res.data.data
        this.countSKU = this.rows.length
        const skuType = this.skuType
        this.rows.forEach(row => {
          row.skuType = skuType
        })
        this.loading = true
      } catch (error) {
        console.log(error)
      }
    },
    addProductPLU() {
      this.$router.push('plu/' + this.productSKUObjectId + this.queryURL)
    },
    addProductBOM() {
      this.$router.push('bom/' + this.productSKUObjectId + this.queryURL)
    },
    addProductSerial() {
      this.$router.push('serial/' + this.productSKUObjectId + this.queryURL)
    },
    onclickChannels() {
      this.$router.push('channels/' + this.productSKUObjectId + this.queryURL)
    },
    getPLUlist() {
      const uid = `${localStorage.getItem('shopsUid')}`
      let params = {
        skuObjectId: this.productSKUObjectId,
        includePLU1: true,
      }
      const headers = { shopObjectId: this.shopObjectId }

      pos({
        url: '/api/v1.0/' + uid + '/productplu/getbysku',
        params: params,
        headers: headers,
        method: 'GET',
      }).then((res) => {
        let plulists = res.data.data
        let counting = plulists.filter((item) => item.isProductPLU1 != true)

        if (plulists != null && counting.length > 0) {
          this.PLUCountShow = '(' + counting.length + ')'
        }
      })
    },
    getBOMList() {
      const uid = `${localStorage.getItem('shopsUid')}`
      let params = { skuObjectId: this.productSKUObjectId }
      const headers = { shopObjectId: this.shopObjectId }

      pos({
        url: '/api/v1.0/' + uid + '/productsku/getbom',
        params: params,
        headers: headers,
        method: 'GET',
      }).then((res) => {
        let bomlists = res.data.data
        if (bomlists != null && bomlists.length > 0) {
          this.BOMCountShow = '(' + bomlists.length + ')'
        }
      })
    },
    async getProductOption() {
      try {
        this.loadingButton = false;
        const uid = this.uid;
        const skuObjectId = this.SKU.objectId;
        let params = { skuObjectId: skuObjectId };
        const res = await pos.get('/api/v1.0/' + uid + '/productsku/getproductoption', { params });
        this.optionGroupActive = [];
        if (res.data.data.deleted_at !== undefined) {
          this.productOption = res.data.data.filter(item => item.deleted_at === undefined);
        } else {
          this.productOption = res.data.data;
        }
        for (let i = 0; i < this.productOption.length; i++) {
          let item = {
            num: i + 1,
            groupActiveName: this.productOption[i].OptionGroup.name,
            groupActiveObjectId: this.productOption[i].OptionGroup.objectId,
            groupActiveId: this.productOption[i].OptionGroup.id,
            activeObjectId: this.productOption[i].objectId,
            activeId: this.productOption[i].id
          };
          this.optionGroupActive.push(item);
        }
        this.loading = true
      } catch (error) {
        console.error(error);
      }
    },
    async getOptionGroup() {
      try {
        this.loading = false;
        const uid = this.uid;
        const shopObjectId = this.shopObjectId;
        let params = { shopObjectId: shopObjectId };
        this.optionGroupdata = []
        await pos
          .get('/api/V1.0/' + uid + '/option/group/get', { params })
          .then((res) => {
            if (res.data.data.deleted_at !== undefined) {
              this.optionGroup = res.data.data.filter(item => item.deleted_at === undefined);
            } else {
              this.optionGroup = res.data.data;
            }
            for (let i = 0; i < this.optionGroup.length; i++) {
              let item = {
                num: i + 1,
                optionGroupName: this.optionGroup[i].name,
                optionGroupObjectId: this.optionGroup[i].objectId,
                optionGroupId: this.optionGroup[i].id
              };
              this.optionGroupdata.push(item);
            }
            this.loadingButton = true;
          });
      } catch (error) {
        console.log(error)
      }
    },
    onSelectedAutoCompleteEvent(item) {
      if (item.selected == true) {
        this.selectedItems.push(
          {
            name: item.name,
            objectId: item.objectId,
            id: item.id
          }
        );
      } else {
        // ถ้า item ถูกยกเลิกการเลือก
        this.selectedItems = this.selectedItems.filter(
          (x) => x.objectId !== item.objectId
        );
      }
    },
    confirmdeleteOptionGroup(item) {
      this.deleteOptionGroupModal = true
      this.itemDelete = item
    },
    async deleteOptionGroup() {
      try {
        this.shop = this.shops.find((i) => i.objectId === this.shopObjectId);
        const uid = `${localStorage.getItem('shopsUid')}`;
        let data = [{
          objectId: this.itemDelete.activeObjectId,
          id: this.itemDelete.activeObjectId,
          optionGroup: {
            objectId: this.itemDelete.groupActiveObjectId,
            name: this.itemDelete.groupActiveName,
            id: this.itemDelete.groupActiveId,
          },
          deleted_at: new Date(),
          productSKU: {
            SKUName: this.SKUName,
            SKUPrice: this.SKUPrice,
            id: this.SKU.id,
            objectId: this.SKU.objectId,
          },
          uid: uid,
          shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
          },
        }];
        const headers = { shopObjectId: this.shopObjectId };
        this.loading = false;

        const response = await pos({
          method: 'post',
          url: '/api/V1.0/' + uid + '/option/activegroup/update',
          data: data,
          headers: headers,
        });

        // กระทำหลังจาก API ทำงานเสร็จ
        setTimeout(() => {
          this.loading = true;
          this.loadingDeleteButton = false;
          this.AddOptionGroupModal = false;
          this.deleteOptionGroupModal = false;
          this.getProductOption();
          this.getOptionGroup();
        }, 1000);
      }
      catch (error) {
        console.log(error);
      }
    },
    async onCheckboxChange() {
      try {
        this.shop = this.shops.find((i) => i.objectId === this.shopObjectId);
        const uid = `${localStorage.getItem('shopsUid')}`;
        // สร้างอ็อบเจ็กต์เพื่อใส่ข้อมูลที่ต้องการส่งไปทีละ object
        let dataArray = this.selectedItems.map((selectedItem) => {          
          return {
            optionGroup: selectedItem,
            productSKU: {
              SKUName: this.SKUName,
              SKUPrice: this.SKUPrice,
              id: this.SKU.id,
              objectId: this.SKU.objectId,
            },
            uid: uid,
            shop: {
              objectId: this.shop.objectId,
              id: this.shop.id,
              branchName: this.shop.branchName,
            },
          };
        });
        // ส่งข้อมูลทีละ object ไปยัง API

        for (const data of dataArray) {
          const headers = { shopObjectId: this.shopObjectId };
          this.loading = false;
          const response = await pos({
            method: 'post',
            url: '/api/V1.0/' + uid + '/option/activegroup/update',
            data: [data],
            headers: headers,
          });

          // กระทำหลังจาก API ทำงานเสร็จ
          setTimeout(() => {
            this.productPRU = false;
            this.addlist = this.selectedItems;
            this.loading = true;
            this.loadingButtonValidatePRU = false;
            this.AddOptionGroupModal = false;
            this.selectedItems = [];
            this.getProductOption();
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.btn-add {
  border-color: #29b289;
  text-align: center;
  width: -webkit-fill-available;
  color: #29b289;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #29b289;
  background-color: #29b289;
}

.form-control {
  cursor: pointer;
}

.custom-select {
  cursor: pointer;
}

.form-control:disabled,
.form-control[readonly] {
  cursor: default;
}

.cursor {
  cursor: pointer;
}

.multiselect__tags {
  min-height: 20px;
  display: block;
  padding: 5px 0 0 5px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
  cursor: pointer;
}

.multiselect__single {
  min-height: 20px;
  display: block;
  padding: 5px 0 0 5px;
  font-size: 14px;
  cursor: pointer;
}

.button-silom {
  left: 0;
  bottom: 0;
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 -2px 6px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 -2px 6px 0 rgb(0 0 0 / 5%);
}



@media screen and (min-width: 568px) {
  .imagelabel {
    text-align: right;
  }
}
@media screen and (max-width: 568px) {
  .col-form-label {
    text-align: left !important;
    margin-bottom: 8px;
  }
}
</style>

<style src="../../assets/styles/imgProduct.css"></style>
